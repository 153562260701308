import md5 from 'md5';
import { getToken } from './token';
/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt'; // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong'; // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
// 密钥对生成 http://web.chacuo.net/netrsakeypair
// rsa公钥：加密
const publicKey = process.env.VUE_APP_RSA_PUBLICKEY; //写入自己的公钥
// rsa私钥：解密
const privateKey = ''; //写入自己的私钥
/* JSEncrypt加密 */
export function rsaPublicData(data) {
  let jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(publicKey);
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  let result = jsencrypt.encrypt(data);
  return result;
}
/* JSEncrypt解密 */
export function rsaPrivateData(data) {
  let jsencrypt = new JSEncrypt();
  jsencrypt.setPrivateKey(privateKey);
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  let result = jsencrypt.encrypt(data);
  return result;
}
/* 加密 */
export function encrypt(data) {
  const PUBLIC_KEY = publicKey;
  let encryptor = new Encrypt();
  encryptor.setPublicKey(PUBLIC_KEY);
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  const result = encryptor.encryptLong(data);
  return result;
}
/* 解密 - PRIVATE_KEY - 验证 */
export function decrypt(data) {
  const PRIVATE_KEY = privateKey;
  let encryptor = new Encrypt();
  encryptor.setPrivateKey(PRIVATE_KEY);
  // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
  let result = encryptor.decryptLong(data);
  return result;
}
export function getTpDataSign(data) {
  const timestamp = new Date().getTime();
  const signData = md5(JSON.stringify(data));
  // const sign = `timestamp=${timestamp}&token=${
  //   JSON.parse(localStorage.getItem('userInfo')).token
  // }&data=${signData}`;
  const sign = `timestamp=${timestamp}&token=${ getToken()}&data=${signData}`;
  const result = { timestamp: timestamp, data: signData, sign: encrypt(sign) };
  return result;
}
