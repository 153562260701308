<template>
  <div class="flex flex-wrap">
    <el-upload
      v-bind="$props"
      :file-list="value"
      :on-change="onChange"
      :auto-upload="false"
      ref="upload"
      class="mr-2 mb-4"
      v-show="value.length < limit"
      :limit="0"
    >
      <slot> </slot>
    </el-upload>

    <el-upload
      v-for="(file, index) in value"
      :key="file.uid"
      v-bind="$props"
      :file-list="value"
      :on-change="onChange"
      :auto-upload="false"
      class="mr-4 mb-4"
      :http-request="uploadFile"
      disabled
      ref="showImg"
    >
      <div
        class="relative w-full h-full leading-none box-border"
        :class="{
          'border-4': selectImgIndex == index,
          'border-primary': selectImgIndex == index,
        }"
        @click="$emit('update:selectImgIndex', selectImgIndex == index ? null : index)"
      >
        <img
          :src="file.url"
          class="w-full h-full block"
          v-if="file.mediaType == 'IMAGE'"
        />
        <video
          :src="file.url"
          class="w-full h-full block"
          v-if="file.mediaType == 'VIDEO'"
          controls
        />
        <div
          class="w-full"
          v-if="file.status == 'uploading'"
        >
          <el-progress
            :stroke-width="10"
            :percentage="file.process"
          ></el-progress>
        </div>
        <!-- 删除 -->
        <div class="absolute -right-2 -top-2 w-6 h-6 leading-none cursor-pointer z-20">
          <i
            class="el-icon-circle-close hover:text-primary"
            @click.stop="deleteItem(index)"
          ></i>
        </div>
        <!-- 重新上传 -->
        <div
          class="z-10 absolute left-0 top-0 w-full h-full bg-white bg-opacity-75 bottom-0 right-0 m-auto flex flex-col justify-center items-center"
          v-if="file.status == 'fail'"
        >
          <div class="text-red-600 mt-3 text-base">
            <i class="el-icon-warning !text-red-600 !text-base"></i> 上传失败
          </div>
          <el-button
            size="mini"
            type="primary"
            @click.stop="uploadFile(file)"
            >重新上传</el-button
          >
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { PropsType } from './index';
export default {
  props: PropsType,
  data() {
    return {};
  },
  methods: {
    updateValue(list) {
      this.$emit('update:value', [...list]);
      this.$emit('change', list);
    },
    deleteItem(index) {
      this.value.splice(index, 1);
      this.updateValue(this.value);
    },
    onChange(file) {
      if (this.value.length >= this.limit) return;
      const uid = file.uid;
      const index = this.value.findIndex((item) => item.uid == uid);
      let list = this.value;
      if (index == -1) {
        let mediaType = file.raw.type.startsWith('image') ? 'IMAGE' : 'VIDEO';
        file.mediaType = mediaType;
        file.name = file.raw.name;
        list.push(file);
        this.updateValue(list);
      }
      this.selectLimit(file)
        .then(() => {
          if (index >= 0) {
            list[index] = file;
            this.updateValue(list);
          } else {
            this.$nextTick(() => {
              this.uploadFile(file);
            });
          }
        })
        .catch((err) => {
          list = [...this.value]
          const i = list.findIndex((item) => item.uid == uid);
          if (i != -1) {
            list.splice(i, 1);
            this.updateValue(list);
          }
          this.$message.error(err);
        });
    },
    onProgress(event, file) {
      const uid = file.uid;
      const index = this.value.findIndex((item) => item.uid == uid);
      const list = this.value;
      let process = event.progress;
      if (index >= 0) {
        list[index].process = process;
        list[index].status = 'uploading';
      }
      this.updateValue(list);
    },
    uploadFile(data) {
      const uid = data.uid;

      this.API(data, { onUploadProgress: this.onProgress })
        .then((res) => {
          const list = this.value;
          const index = list.findIndex((item) => item.uid == uid);
          if (index > -1) {
            if (res.code == 0) {
              list[index] = { ...list[index], ...res.data, url: res.data.url, status: 'success' };
            } else {
              list[index] = { ...list[index], status: 'fail', process: null };
            }
            this.updateValue(list);
          }
        })
        .catch(() => {
          const list = this.value;
          const index = list.findIndex((item) => item.uid == uid);
          list[index] = { ...list[index], status: 'fail', process: null };
          this.updateValue(list);
        });
    },
  },
};
</script>
