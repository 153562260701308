import axios from 'axios';
import { Message } from 'element-ui';
import { getToken } from './token';
axios.defaults.headers['Content-Type'] = 'application/json';

const service = axios.create({
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    },
  ],
});

service.interceptors.request.use(
  config => {
    const token = getToken();
    config.headers['token'] = token;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  response => {
    let res = response.data;
    if (res.code != 0 && res.code != 200 && res.code != 11) {
      Message({
        message: res.comment || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(res);
    }
    return res;
  },
  error => {
    if (error == 'Cancel') {
      return Promise.reject(error);
    }
    Message({
      message: error.message == 'timeout of 20000ms exceeded' ? '操作超时!' : error.message,
      type: 'error',
      duration: 5 * 1000,
    });

    return Promise.reject(error);
  },
);

const GET = (url, params, config) => {
  return service({
    url,
    method: 'GET',
    params,
    ...config,
  }).then(res => {
    return res?.data;
  });
};

const POST = (url, data, config) => {
  return service({
    url,
    method: 'POST',
    data,
    ...config,
  }).then(res => {
    return res?.data;
  });
};
export { GET, POST };
export default service;
