export const selectTreePropsType = {
  value: {
    type: Array,
    default: () => [],
  },
  showTag: {
    type: Boolean,
    default: true
  },
  // treeProps
  data: {
    type: Array,
  },
  emptyText: {
    type: String,
  },
  renderAfterExpand: {
    type: Boolean,
    default: true,
  },
  nodeKey: {
    type: String,
    default: 'id'
  },
  checkStrictly: Boolean,
  defaultExpandAll: {
    type: Boolean,
    default: true
  },
  expandOnClickNode: {
    type: Boolean,
    default: true,
  },
  checkOnClickNode: Boolean,
  checkDescendants: {
    type: Boolean,
    default: false,
  },
  autoExpandParent: {
    type: Boolean,
    default: true,
  },
  defaultCheckedKeys: Array,
  defaultExpandedKeys: Array,
  currentNodeKey: [String, Number],
  renderContent: Function,
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  allowDrag: Function,
  allowDrop: Function,
  props: {
    default() {
      return {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      };
    },
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  highlightCurrent: Boolean,
  load: Function,
  filterNodeMethod: Function,
  accordion: Boolean,
  indent: {
    type: Number,
    default: 18,
  },
  iconClass: String,
};
