import request from '../utils/request.js';
import { getTpDataSign } from '../utils/rsa.js';
const baseUrl = process.env.VUE_APP_GIAM_Common;
// 添加标签
export function saveConfigLabel(data) {
  return request({
    baseURL: baseUrl,
    url: '/ads/saveConfigLabel',
    method: 'post',
    data: JSON.stringify(data),
    headers: { ...getTpDataSign(data) },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除标签
export function delLabel(data) {
  return request({
    baseURL: baseUrl,
    url: `/ads/delLabel?labelId=${data.labelId}`,
    method: 'post',
    data: JSON.stringify(data),
    headers: { ...getTpDataSign(data) },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 添加标签广告关联关系
export function saveAdLabel(data) {
  return request({
    baseURL: baseUrl,
    url: `/ads/saveAdLabel`,
    method: 'post',
    data: JSON.stringify(data),
    headers: { ...getTpDataSign(data) },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除标签广告的关联关系
export function delAdLabel(data) {
  return request({
    baseURL: baseUrl,
    url: `/ads/delAdLabel`,
    method: 'post',
    data: JSON.stringify(data),
    headers: { ...getTpDataSign(data) },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
