import Cookie from 'js-cookie';
let getToken = () => {
  if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {
    if (window.location.hash.startsWith('#/fastDelivery')) {
      return localStorage.getItem('token_workFlow');
    }
    return Cookie.get('token');
  }
  return Cookie.get('token', {
    domain: '.giikin.com',
  });
};

const getUserId = () => {
  return Cookie.get('sso_user_id', {
    domain: '.giikin.com',
  });
};

const setToken = (token) => {
  if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {
    Cookie.set('token', token);
  }
};

export { getToken, getUserId, setToken };
